<template>
  <div class="vqb-rule">
    <div class="vqb-label">{{ model.x }}: <b>{{ model.xType }}</b></div>

    <div class="vqb-edit">
      <div class="vqb-edit__input">
        <el-input v-model="model.x" placeholder="Y" class="e-input"></el-input>
      </div>

      <div class="vqb-edit__input">
        <el-select
          v-model="model.xType"
          placeholder="X Type"
          class="e-input"
          popper-class="e-input__popper"
        >
          <el-option
            v-for="operator in valueTypes"
            :key="operator.value"
            :value="operator.value"
            :label="operator.label"
          ></el-option>
        </el-select>
      </div>

      <div class="vqb-edit__input">
        <el-select
          v-model="model.operator"
          placeholder="Operator"
          class="e-input"
          popper-class="e-input__popper"
        >
          <el-option
            v-for="operator in operators"
            :key="operator.value"
            :value="operator.value"
            :label="operator.label"
          ></el-option>
        </el-select>
      </div>

      <div class="vqb-edit__input">
        <el-select
          v-model="model.yType"
          placeholder="Y Type"
          class="e-input"
          popper-class="e-input__popper"
        >
          <el-option
            v-for="operator in valueTypes"
            :key="operator.value"
            :value="operator.value"
            :label="operator.label"
          ></el-option>
        </el-select>
      </div>

      <div class="vqb-edit__input">
        <el-input v-model="model.y" placeholder="Y" class="e-input"></el-input>
      </div>

      <div class="vqb-edit__actions">
        <el-tooltip :open-delay="300" class="item" effect="dark" content="Remove expression" placement="top">
          <el-button
            size="mini"
            type="danger"
            @click="remove"
            v-html="labels.removeExpression"
          ></el-button>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script type="ts">
import Vue from 'vue'
import { cloneObject } from '@/helpers'

export default Vue.extend({
  name: 'ExpressionBuilderExpression',

  props: {
    value: {
      type: Object,
      required: true
    },

    index: {
      type: Number,
      default: 0
    },

    labels: {
      type: Object,
      required: true
    },

    sizeControl: {
      type: String,
      default: 'mini'
    },

    valueTypes: {
      type: Array,
      default () {
        return []
      }
    },

    operators: {
      type: Array,
      default () {
        return []
      }
    }
  },

  watch: {
    value: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.model)) {
          this.model = cloneObject(value)
        }
      },
      deep: true
    },

    model: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.value)) {
          this.$emit('input', cloneObject(value))
        }
      },
      deep: true
    }
  },

  data () {
    return {
      model: this.value || {
        x: 'attr_N_',
        xType: 'attribute',
        operator: 'eq',
        y: '',
        yType: 'constant'
      }
    }
  },

  mounted () {
    this.model = this.value
  },

  methods: {
    remove () {
      this.$confirm(
        'Do you really want to delete the expression?',
        'Warning',
        {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }
      ).then(() => {
        this.$emit('child-deletion-requested', this.index)
      }).catch(() => null)
    }
  }
})
</script>
