<template>
  <div class="vqb-group" :class="{ ['depth-' + depth]: true }">
    <div class="vqb-group-body">
      <div class="vqb-label">{{ labels.groupLabel }}</div>

      <div class="vqb-edit">
        <div class="vqb-edit__input">
          <el-select
            v-model="model.logicalOperator"
            class="e-input"
            popper-class="e-input__popper"
          >
            <el-option
              v-for="(label, index) in labels.matchTypes"
              :key="index"
              :label="label.label"
              :value="label.value"
            ></el-option>
          </el-select>
        </div>

        <div class="vqb-edit__actions">
          <el-button
            @click="expand()"
            size="mini"
            type="primary"
          >
            <i v-if="isExpand" class="el-icon-arrow-up"></i>
            <i v-else class="el-icon-arrow-down"></i>
          </el-button>

          <el-tooltip :open-delay="300" class="item" effect="dark" content="Add expression" placement="top">
            <el-button
              @click="addExpression"
              size="mini"
              type="primary"
              v-html="labels.addExpression"
            ></el-button>
          </el-tooltip>

          <el-tooltip :open-delay="300" class="item" effect="dark" content="Add group" placement="top">
            <el-button
              @click="addGroup"
              size="mini"
              type="default"
              v-html="labels.addGroup"
              v-if="depth < maxDepth"
            ></el-button>
          </el-tooltip>

          <el-tooltip :open-delay="300" class="item" effect="dark" content="Remove group" placement="top">
            <el-button
              @click="remove"
              size="mini"
              type="danger"
              v-html="labels.removeGroup"
              v-if="depth > 1">
            </el-button>
          </el-tooltip>
        </div>
      </div>

      <transition name="slide">
        <div v-show="isExpand" class="vqb-children">
          <component
            v-for="component in componentProps"
            v-model="model.children[component.bind.index].expression"
            :key="component.key"
            :is="component.name"
            v-bind="component.bind"
            v-on:child-deletion-requested="removeChild"
            class="vqb-child"
          ></component>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import ExpressionBuilderExpression from './ExpressionBuilderExpression.vue'
import { cloneObject } from '@/helpers'

export default Vue.extend({
  name: 'ExpressionBuilderGroup',

  components: {
    ExpressionBuilderExpression
  },

  props: {
    value: {
      type: Object,
      required: true
    },

    depth: {
      type: Number,
      default: 1
    },

    maxDepth: {
      type: Number,
      default: 5
    },

    index: {
      type: Number,
      default: 0
    },

    labels: {
      type: Object,
      required: true
    },

    sizeControl: {
      type: String,
      default: 'mini'
    },

    valueTypes: {
      type: Array,
      default () {
        return []
      }
    },

    operators: {
      type: Array,
      default () {
        return []
      }
    },

    expanded: {
      type: Boolean,
      default: false
    },

    childExpanded: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    componentProps () {
      const result = []

      for (const [index, child] of this.model.children.entries()) {
        const bind = {
          index,
          labels: this.labels,
          sizeControl: this.sizeControl,
          operators: this.operators,
          valueTypes: this.valueTypes
        }

        if (child.type === 'group') {
          bind.depth = this.depth + 1
          bind.maxDepth = this.maxDepth
          bind.expanded = !this.expanded
            ? this.childExpanded
            : this.expanded
        }

        result.push({
          key: index,
          name: this.componentMap[child.type],
          bind
        })
      }

      return result
    }
  },

  watch: {
    value: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.model)) {
          this.model = cloneObject(value)
        }
      },
      deep: true
    },

    model: {
      handler: function (value) {
        if (JSON.stringify(value) !== JSON.stringify(this.value)) {
          this.$emit('input', cloneObject(value))
        }
      },
      deep: true
    }
  },

  data () {
    return {
      model: this.value,
      componentMap: {
        expression: 'expression-builder-expression',
        group: 'expression-builder-group'
      },
      isExpand: this.expanded
    }
  },

  methods: {
    expand () {
      this.isExpand = !this.isExpand
    },

    addExpression () {
      this.model.children.push({
        type: 'expression',
        expression: {
          x: 'attr_N_',
          xType: 'field',
          xParams: {},
          operator: 'eq',
          y: 'str str str',
          yType: 'constant',
          yParams: {
            fetchAs: 'string'
          }
        }
      })
    },

    addGroup () {
      if (this.depth < this.maxDepth) {
        this.model.children.push({
          type: 'group',
          expression: {
            logicalOperator: 'and',
            children: []
          }
        })
      }
    },

    remove () {
      if (this.model.children.length > 0) {
        this.$confirm('Do you really want to delete a group of expressions?', 'Warning', {
          confirmButtonText: 'Delete',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.$emit('child-deletion-requested', this.index)
        }).catch(() => null)
      } else {
        this.$emit('child-deletion-requested', this.index)
      }
    },

    removeChild (index) {
      this.model.children.splice(index, 1)
    }
  }
})
</script>

<style lang="scss">
  .vqb-group .vqb-group-body,
  .vqb-group .vqb-children > .vqb-child:last-child {
    margin: 10px 0;
  }

  .vqb-edit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 15px;
  }

  .vqb-edit__input {
    width: 100%;
    margin-right: 10px;
  }

  .vqb-edit__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;

    .el-tooltip + .el-tooltip {
      margin-left: 10px;
    }
  }

  .slide-enter-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }

  .slide-leave-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  }

  .slide-enter-to, .slide-leave {
    max-height: 100px;
    overflow: hidden;
  }

  .slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
  }
</style>
